@import "@spartacus/product-configurator";
@import "../rational-theme/rational-commons";

.ProductListPageTemplate, .SearchResultsListPageTemplate {
    cx-configure-product {
        a.btn.btn-primary {
            @extend %non-affirmative-buttons;
            padding: 15px 30px;
            width: 210px;
        }
    }
}

cx-configurator-previous-next-buttons .btn-block.btn-secondary.cx-previous,
.btn-block.btn-secondary.cx-next {
    margin-top: 0px !important;
}

cx-configurator-tab-bar {
    &:not(:empty) {
        .cx-tab-bar a {

            &.active,
            &:hover {
                color: var(--cx-color-primary);
            }
        }
    }
}