
@import "./rational-colors";
@import "./rational-fonts";
@import "./rational-icons";
@import "./basegraphics";


body {
  --cx-color-background: #{$secondary_white};
  --cx-color-text: #{$primary_darkgrey};
  --cx-color-text-inverse: #{$secondary_white};
  --cx-color-primary: #{$primary_red};
  --cx-color-secondary: #{$primary_darkgrey};
  --cx-color-success: #{$additive_green};
  --cx-color-info: #{$additive_blue};
  --cx-color-warning: #{$additive_yellow};
  --cx-color-danger: #{$primary_red};

  --cx-color-border: #{$primary_grey};

  --cx-color-primary-button: #{$secondary_white};
  --cx-color-primary-button-border: #{$primary_darkgrey};
  --cx-color-primary-button-hover: #{$primary_darkgrey};
  --cx-color-links: #{$additive_darkblue};
  --cx-color-links_hover: #{$additive_blue};
  --cx-font-size: #{$font_size_normal};
  --cx-font-size-small: #{$font_size_normal};
  --cx-font-size-h1: #{$font_size_h1};
  --cx-font-size-h1-alt: #{$font_size_h1_alt};
  --cx-font-size-h2: #{$font_size_h2};
  --cx-font-size-h3: #{$font_size_h3};
  --cx-font-size-h4: #{$font_size_h4};
  --cx-page-width-max: 1170px;
  --cx-buttons-border-radius: 0rem;
  --cx-text-transform: none;

  font-size: var(--cx-font-size) !important;
}


html,
body {
  width: auto !important;
  overflow-x: hidden !important
}

h1 {
  font-size: var(--cx-font-size-h1) !important;
  font-weight: var(--cx-font-weight-normal) !important;
}

h2 {
  font-size: var(--cx-font-size-h2) !important;
  font-weight: var(--cx-font-weight-normal) !important;
}

h3 {
  font-size: var(--cx-font-size-h3) !important;
  font-weight: var(--cx-font-weight-normal) !important;
}

h4 {
  font-size: var(--cx-font-size-h4) !important;
  font-weight: var(--cx-font-weight-normal) !important;
}

cx-breadcrumb h1 {
  font-size: var(--cx-font-size-h3) !important;
  text-transform: unset !important;
}

.fa, .fas {
  font-weight: 900 !important;
}

.form-control {
  font-size: var(--cx-font-size) !important;
}

a.btn, button.btn {
  border-radius: 5px !important;
  border-width: 2px !important;
  padding: 10px 30px;
  filter: unset !important;
  min-width: unset !important;
  //width: auto !important;

  color: var(--cx-color-text);
  background-color: var(--cx-color-primary-button);
  border-color: var(--cx-color-primary-button-border);

  font-weight: var(--cx-font-weight-bold) !important;
  font-size: var(--cx-font-size) !important;

  & * {
    font-weight: var(--cx-font-weight-bold) !important;
    font-size: var(--cx-font-size) !important;
    margin: 5px 5px 5px 5px;
  }

  &:hover, &:focus, &:active {
    color: var(--cx-color-text);
    background-color: var(--cx-color-primary-button);
    border-color: var(--cx-color-primary-button-border);
  }
}

rational-tab-paragraph-container {
  @extend %cx-tab-paragraph-container;
}

cx-pagination > a {
  border-radius: 5px !important;
  filter: unset !important;

  color: var(--cx-color-text) !important;
  background-color: var(--cx-color-primary-button) !important;
  border-color: var(--cx-color-primary-button-border) !important;

  font-weight: var(--cx-font-weight-bold) !important;
  font-size: var(--cx-font-size) !important;

  &.current {
    color: var(--cx-color-text-inverse) !important;
    background-color: var(--cx-color-primary-button-hover) !important;
    border-color: var(--cx-color-primary-button-hover) !important;
  }

  &:hover {
    color: var(--cx-color-text-inverse) !important;
    background-color: var(--cx-color-primary-button-hover) !important;
    border-color: var(--cx-color-primary-button-hover) !important;
  }
}

cx-product-image-zoom-trigger .cx-action-link {
  height: auto !important;
}

.rational-container, cx-breadcrumb {
  @media (min-width: 768px) {
    width: 750px;
  }
  @media (min-width: 992px) {
    width: 970px;
  }
  @media (min-width: 1200px) {
    width: 1170px;
  }
}

cx-breadcrumb {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  nav {
    display: block;

    ol>li:nth-of-type(2)>a {
      text-transform: unset !important;
    }
  }
}

.ProductDetailsPageTemplate {
  cx-breadcrumb h1 {
    display: none;
  }

  .Tabs {

    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    @media (min-width: 768px) {
      width: 750px;
    }
    @media (min-width: 992px) {
      width: 970px;
    }
    @media (min-width: 1200px) {
      width: 1170px;
    }

    rational-tab-paragraph-container {
      display: block;
      grid-template-columns: unset;
      width: 100%;
      width: -moz-available;
      width: -webkit-fill-available;
      width: stretch;

      button {
        margin-right: 20px;
      }
    }
  }
}


cx-navigation-ui > nav > cx-generic-link > a {
  span {
  @media (min-width: 992px) {
    padding-top: unset;
    padding-inline-end: unset;
    padding-bottom: unset;
    padding-inline-start: unset;
  }
}

  &:hover {
    color: var(--cx-color-text-inverse) !important;
    background-color: var(--cx-color-primary-button-hover) !important;
    border-color: var(--cx-color-primary-button-hover) !important;
  }
}

header .SiteContext label {
  color: black !important;
  select>option {
    font-size: 12px;
  }
  .cx-icon {
    margin-bottom: 2px;
  }
}


header.is-expanded:after {
  @media (max-width: 991.98px) {
    content: "";
    display: block;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: transparent !important;
    z-index: 2;
  }
}


header.rational-header.is-expanded .NavigationBar cx-navigation-ui > nav:hover {
  color: var(--cx-color-text) !important;
  background-color: var(--cx-color-background) !important;
  border-color: var(--cx-color-primary-button-hover) !important;
}

header.rational-header {
  background: none;
  @media (max-width: 991px) {
    padding-top: 0 !important;
    &.is-expanded .navigation {
      background: none !important;
    }
  }
}

cx-navigation-ui.flyout span, cx-navigation-ui.flyout cx-generic-link a {
  @media (max-width: 991.98px) {
    padding: unset;
  }
}



.LandingPage2Template .Section1 {
  max-width: 100% !important;
  margin-top: 0 !important;
}
.LandingPage2Template .Section3 {
  max-width: 100% !important;
  & > * {
    max-width: var(--cx-page-width-max) !important;
  }
}

cx-page-layout.LandingPage2Template {
  max-width: 100% !important;
}
cx-page-layout {
  &.footer {
    background-color: transparent !important;
  }
}

cx-file-upload {
  p {
    display: none !important;
  }
}

%cx-category-navigation {
  span,
  button:not(cx-generic-link + button),
  cx-navigation-ui>nav>ul>li>cx-generic-link {
    text-transform: none !important;
  }
}

@include media-breakpoint-up(lg) {
  cx-navigation-ui nav {
    ul.childs > li > cx-generic-link > a {
      padding: unset !important;
    }
  }
}

@media (max-width: 768px) {
  .slider-row-height {
    height: 300px;
  }
}


cx-breadcrumb > nav > ol > li > a{
  text-transform: none !important;
}
cx-breadcrumb > nav > ol > li {
  &:not(:last-child):after {
    font-family: 'raticons' !important;
    speak: none;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    content: '\e902' !important;
  }
}

.cx-icon.fa-angle-down{
  font-family: 'raticons' !important;
  speak: none;
  /*font-size: 28px;*/
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
}

.cx-icon.fa-angle-down {
    &:before {
      content: "\e93a" !important;
    }
}


cx-wish-list button.cx-remove-btn {
  margin-top: 15px !important;
}

cx-carousel {
  .previous,
  .next {
    color: var(--cx-color-medium) !important;

    &:not(:disabled):hover {
      color: var(--cx-color-primary)  !important;
    }
  }
}


cx-carousel .indicators {
  flex-flow:row wrap;
  display: flex;

  button {
    .cx-icon {
      border: none !important;
    }

    &:not(:disabled) .cx-icon {
      color: var(--cx-color-medium) !important;
    }

    &:not(:disabled):hover .cx-icon {
      color: var(--cx-color-secondary) !important;
    }

    &:disabled .cx-icon {
      color: var(--cx-color-primary) !important;
    }
  }
}

cx-product-image-zoom-dialog cx-carousel {
  .slides .slide  div.item {
    max-width: 85px;
  }
}


@media only screen and (max-width: 900px) and (orientation: landscape) {
  cx-product-image-zoom-view cx-product-image-zoom-thumbnails .thumbs {
    display: block !important;
  }
}

cx-product-image-zoom-view cx-media img {
  object-fit: contain;
}

.cx-add-to-saved-cart-container {
  .cx-action-link {
    &:nth-child(n + 2) {
      &:before {
        @media (max-width: 470px) {
          content: unset !important;
        }

        @media (min-width: 471px) {
          content: '|';
        }
      }
    }
  }
}

rational-tab-paragraph-container > div > button > span.accordion-icon:before {
  bottom: unset !important;
}

.fa-th-large::before, .fa-bars::before {
  margin-left: -10px;
}

%cx-facet {
  button.heading {
    text-align: left !important;
    word-break: break-word !important;
    hyphens: auto;
  }
}

.cx-name {
  display: flex !important;
  justify-content: row;

  rational-product-description {
    margin-left: 5px;

    @media only screen and (min-width: 768px) {
      .extra-info {
          left: unset !important;
      }
    }
  }
}

.stickyGlobalMsg {
  width: 100%;
  position: fixed !important;
  top: 5px;
  z-index: 100;
  opacity: 80%;
}

.cx-product-search-list, .cx-item-list-items, .refComponent, rational-product-list, cx-page-layout.ProductDetailsPageTemplate cx-page-slot.Summary, rational-cart-item, cx-searchbox > .results > .products{
  %cx-media {
  &.is-missing {
    @extend %rational-cx-media-missing !optional;
    height: 100%;
  }

  &.is-loading {
    &::after{
      z-index: -1;
    }
  }
}

}
%rational-cx-media-missing {
  background: no-repeat top center
    url('../../assets/images/515x515-Icon_eCommerce_Shop_placeholder_Combined.png') !important;
  background-size: contain !important;
}



cx-review-submit .cx-review-summary-card,
cx-order-overview .cx-summary-card {
	cx-card .cx-card-label-bold {
		font-weight: normal;
	}
	&.cx-review-card-address cx-card .cx-card-label-bold {
			font-weight: bold;
	}
}

.ngb-dp-header {
  margin-bottom: 5px !important;
}


rational-cart-item-list {
  @media (max-width: 991.98px) {
    td:not(:nth-of-type(1)) {
      margin-inline-start: 0px !important;
    }
  }

  .cx-table-item-container {
    grid-template: unset;
    display: block !important;
  }
}

