@charset "UTF-8";
a[name]:target:before {
  content: "";
  display: block;
  height: 120px;
  margin: -120px 0 0;
}

.regularTable table {
  font-size: 16px;
}

@media (max-width: 991px) {
  .regularTable table {
    font-size: 16px;
  }
}
@media (max-width: 767px) {
  .regularTable table {
    font-size: 16px;
  }
}
/***
 *
 * csscontainer merge
 *
 */
section {
  margin-bottom: 0;
}

p {
  font-size: 16px;
  margin: 0 0 12px;
}

.content-bottom-text-link a, .QuarterBlock a {
  margin-left: 0;
}

section.short-space {
  margin-bottom: 0;
}

.noTransition {
  transition: none;
}

.progress-bar-grey {
  background-image: none;
  background-color: lightgray;
}

.progress-animated-numbers-height {
  height: 20px;
}

.dot {
  height: 25px;
  width: 25px;
  background-color: lightgray;
  border-radius: 4px;
  display: inline-block;
}

.progress-bar {
  transition: none;
}

.progress {
  position: relative;
  top: -16px;
  height: 3px;
}

@media (max-width: 992px) {
  .content-video-pic-container {
    margin-bottom: 5px;
  }
}
/* css container end */
html {
  scroll-behavior: smooth;
}

@media screen and (prefers-reduced-motion: reduce) {
  html {
    scroll-behavior: auto;
  }
}
body {
  color: #333;
  font-family: "RATIONALSans", sans-serif !important;
  font-size: 14px;
  letter-spacing: unset;
  line-height: 1.6;
}

body a:hover {
  text-decoration: none;
}

.display_block {
  display: block;
}

.display_none {
  display: none;
}

.of-hidden {
  overflow: hidden;
}

.top-0 {
  top: 0;
}

.right-0 {
  right: 0;
}

.padding-0 {
  padding: 0;
}

.bottom-0 {
  bottom: 0;
}

.z-index-1 {
  z-index: 1;
}

.width-100 {
  width: 100%;
}

@media only screen and (max-width: 767px) {
  .width-xs-100 {
    width: 100%;
  }
}
.height-100 {
  height: 100%;
}

.all_centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.vertical_centered {
  top: 50%;
  transform: translateY(-50%);
}

.horizontal_centered {
  left: 50%;
  transform: translateX(-50%);
}

@media only screen and (max-width: 767px) {
  .no-mobil-margin {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
}

@media only screen and (max-width: 992px) {
  .md-text-center {
    text-align: center;
  }
}
@media only screen and (max-width: 767px) {
  .text-xs-center {
    text-align: center;
  }
}
.row-eq-height {
  display: flex;
}
@media only screen and (max-width: 767px) {
  .row-eq-height {
    display: block;
  }
}

.container-fluid.no-padding {
  padding-left: 0;
  padding-right: 0;
}
.container-fluid.grey {
  background-color: #F4F5F5;
}

@media only screen and (min-width: 1720px) {
  .container.overview-tile-container {
    width: 1710px;
  }
}
.container-fluid.no-padding {
  padding-left: 0;
  padding-right: 0;
}

.no-margin {
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .mobile-padding > [class*=col-] {
    padding-right: 5px;
    padding-left: 5px;
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 991px) {
  .modal-dialog {
    min-width: 945px;
  }
}
/* PADDINGS */
.p-0 {
  padding: 0;
}

.p-15 {
  padding: 15px;
}

@media only screen and (max-width: 767px) {
  .p-xs-0 {
    padding: 0;
  }

  .p-xs-40 {
    padding: 40px;
  }
}
.p-t-b-20-20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.p-t-b-25-0 {
  padding-top: 25px;
  padding-bottom: 0;
}

.p-t-b-35-0 {
  padding-top: 35px;
  padding-bottom: 0;
}

.p-t-5 {
  padding-top: 5px;
}

.p-t-8 {
  padding-top: 8px;
}

.p-t-13 {
  padding-top: 13px;
}

.p-t-15 {
  padding-top: 15px;
}

.p-t-20 {
  padding-top: 20px;
}

.p-t-25 {
  padding-top: 25px;
}

.p-t-30 {
  padding-top: 30px;
}

.p-t-35 {
  padding-top: 35px;
}

.p-t-40 {
  padding-top: 40px;
}

.p-t-55 {
  padding-top: 55px;
}

.p-t-70 {
  padding-top: 70px;
}

.p-t-110 {
  padding-top: 110px;
}

@media only screen and (max-width: 767px) {
  .p-t-xs-12 {
    padding-top: 12px;
  }

  .p-t-xs-13 {
    padding-top: 13px;
  }

  .p-t-xs-15 {
    padding-top: 15px;
  }

  .p-t-xs-20 {
    padding-top: 20px;
  }

  .p-t-xs-24 {
    padding-top: 24px;
  }

  .p-t-xs-25 {
    padding-top: 25px;
  }

  .p-t-xs-35 {
    padding-top: 35px;
  }

  .p-t-xs-40 {
    padding-top: 40px;
  }

  .p-t-xs-55 {
    padding-top: 55px;
  }

  .p-t-xs-70 {
    padding-top: 70px;
  }
}
@media only screen and (max-width: 1199px) {
  .p-t-md-10 {
    padding-top: 10px;
  }
}
.p-b-10 {
  padding-bottom: 10px;
}

.p-b-20 {
  padding-bottom: 20px;
}

.p-b-40 {
  padding-bottom: 40px;
}

.p-b-45 {
  padding-bottom: 45px;
}

.p-b-55 {
  padding-bottom: 55px;
}

.p-b-70 {
  padding-bottom: 70px;
}

.p-b-110 {
  padding-bottom: 110px;
}

@media only screen and (max-width: 767px) {
  .p-b-xs-10 {
    padding-bottom: 10px;
  }

  .p-b-xs-20 {
    padding-bottom: 20px;
  }
}
.p-l-0 {
  padding-left: 0;
}

.p-l-10 {
  padding-left: 10px;
}

.p-l-15 {
  padding-left: 15px;
}

.p-l-40 {
  padding-left: 40px;
}

.p-r-0 {
  padding-right: 0;
}

.p-r-10 {
  padding-right: 10px;
}

.p-r-15 {
  padding-right: 15px;
}

.p-r-60 {
  padding-right: 60px;
}

.p-r-80 {
  padding-right: 80px;
}

@media only screen and (max-width: 767px) {
  .p-r-xs-0 {
    padding-right: 0;
  }

  .p-r-xs-15 {
    padding-right: 15px;
  }

  .p-l-xs-0 {
    padding-left: 0;
  }

  .p-l-xs-15 {
    padding-left: 15px;
  }
}
.p-b-t-55-55 {
  padding-top: 55px;
  padding-bottom: 55px;
}

@media only screen and (max-width: 767px) {
  .p-b-t-xs-30-0 {
    padding-top: 30px;
    padding-bottom: 0;
  }

  .p-b-t-xs-15-15 {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
/* MARGINS */
.m-t-0 {
  margin-top: 0;
}

.m-t-20 {
  margin-top: 20px;
}

.m-b-0 {
  margin-bottom: 0;
}

.m-b-5 {
  margin-bottom: 5px;
}

.m-b-10 {
  margin-bottom: 10px;
}

.m-b-15 {
  margin-bottom: 15px;
}

.m-b-30 {
  margin-bottom: 30px;
}

.m-b-45 {
  margin-bottom: 45px;
}

.m-b-60 {
  margin-bottom: 60px;
}

.m-l-0 {
  margin-left: 0;
}

.m-b-t-20-20 {
  margin-top: 20px;
  margin-bottom: 20px;
}

/* POSITION */
.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

/* ROTATE */
.rotate45 {
  position: relative;
  display: inline-block;
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.rotate90 {
  position: relative;
  display: inline-block;
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
}

.rotate135 {
  position: relative;
  display: inline-block;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

.rotate270 {
  position: relative;
  display: inline-block;
  -webkit-transform: rotate(270deg);
  -moz-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  transform: rotate(270deg);
}

/* COLOR */
.fc-white {
  color: white;
}

.fc-red {
  color: #E30613;
}

.fc-blue {
  color: #0D3B76;
}

.fc-yellow {
  color: #FCD500;
}

.fc-turquise {
  color: #01ABE8;
}

.fc-green {
  color: #A2C50F;
}

.fc-success {
  color: #5cb85c;
}

.fc-info {
  color: #5bc0de;
}

.fc-warning {
  color: #f0ad4e;
}

.fc-danger {
  color: #d9534f;
}

.fc-dark-grey {
  color: #3C3C3C;
}

.fc-meta-nav-gey {
  color: #7d7d7d;
}

.fc-lightgray {
  color: lightgray;
}

.fc-ultralightgrey {
  color: #f6f6f6;
}

.bgc-white {
  background-color: white;
}

.bgc-red {
  background-color: #E30613;
}

.bgc-lightgray {
  background-color: lightgray;
}

.bgc-grey {
  background-color: grey;
}

.bgc-ultralightgrey {
  background-color: #f6f6f6;
}

/* FONT */
.fs-11 {
  font-size: 11px;
}

.fs-14 {
  font-size: 14px;
}

.fs-16 {
  font-size: 16px;
}

.fs-18 {
  font-size: 18px;
}

.fs-20 {
  font-size: 20px;
}

.fs-22 {
  font-size: 22px;
}

.fs-24 {
  font-size: 24px;
}

.fs-28 {
  font-size: 28px;
}

.fs-32 {
  font-size: 32px;
}

.fs-34 {
  font-size: 34px;
}

.fs-44 {
  font-size: 44px;
}

.fs-54 {
  font-size: 54px;
}

.fs-60 {
  font-size: 60px;
}

.fs-76 {
  font-size: 76px;
}

@media only screen and (max-width: 767px) {
  .fs-xs-14 {
    font-size: 14px;
  }

  .fs-xs-16 {
    font-size: 16px;
  }

  .fs-xs-18 {
    font-size: 18px;
  }

  .fs-xs-20 {
    font-size: 20px;
  }

  .fs-xs-22 {
    font-size: 22px;
  }

  .fs-xs-23 {
    font-size: 23px;
  }

  .fs-xs-24 {
    font-size: 24px;
  }

  .fs-xs-26 {
    font-size: 26px;
  }

  .fs-xs-28 {
    font-size: 28px;
  }

  .fs-xs-30 {
    font-size: 30px;
  }

  .fs-xs-32 {
    font-size: 32px;
  }

  .fs-xs-48 {
    font-size: 48px;
  }
}
.fw-bold {
  font-weight: bold;
}

.fw-normal {
  font-weight: normal;
}

.fw-lighter {
  font-weight: lighter;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-justify {
  text-align: justify;
}

.box {
  position: relative;
  width: 100%;
  background-size: cover;
}
.box:before {
  content: "";
  display: block;
  padding-top: 100%;
}
.box.ratio_16_9:before {
  padding-top: 56.25%;
}

@media only screen and (max-width: 767px) {
  .box.xs-ratio_1_1:before {
    padding-top: 100%;
  }
}
.modal_trigger:hover div.icon-raticons.icon-sn_search {
  font-size: 40px;
  transition: font-size 0.2s ease;
}

.modal.in .modal-dialog.vertical_centered {
  top: 50%;
  transform: translateY(-50%);
}

.shadowed_dark {
  text-shadow: 3px 3px 7px #000;
}

.shadowed {
  text-shadow: 2px 2px 5px #666;
}

.gradient_overlay_linear {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(to bottom, rgba(0, 14, 34, 0) 0%, rgba(53, 62, 73, 0.44) 70%, rgba(53, 62, 73, 0.44) 100%);
}

.gradient_overlay_circled {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: radial-gradient(circle, rgba(48, 62, 84, 0.14) 0%, rgba(49, 64, 84, 0.21) 60%, #1b2733 100%);
}

/* NAVIGATION*/
/*
*
*	Mobile-Navi
*
*/
nav.navbar.navbar-default {
  background-color: white;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.5) 0 2px 4px;
  margin-bottom: 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 4;
  max-height: 100vh;
  overflow: hidden auto;
  font-size: 16px;
}
nav.navbar.navbar-default .navbar-header {
  margin: 0;
  float: none;
}
nav.navbar.navbar-default .navbar-header .navbar-brand {
  background-image: none;
  height: 20px;
  width: auto;
  padding: 0;
  margin: 15px 10px;
}
nav.navbar.navbar-default .navbar-header .navbar-brand img {
  height: 20px;
}
nav.navbar.navbar-default .navbar-header .navbar-toggle {
  height: 20px;
  padding: 0;
  border: none;
  background: none;
  margin: 11px 10px 15px;
  font-size: 20px;
}
nav.navbar.navbar-default .navbar-collapse ul.nav.navbar-nav {
  margin: 0 -15px;
}
nav.navbar.navbar-default .navbar-collapse ul.nav.navbar-nav > li a.active {
  font-weight: normal;
  color: #333;
}
nav.navbar.navbar-default .navbar-collapse ul.nav.navbar-nav > li > a {
  padding: 20px 25px;
  text-align: left;
  font-weight: normal;
}
nav.navbar.navbar-default .navbar-collapse ul.nav.navbar-nav > li > a > span.icon-arrow_left {
  display: none;
}
nav.navbar.navbar-default .navbar-collapse ul.nav.navbar-nav > li > a.active, nav.navbar.navbar-default .navbar-collapse ul.nav.navbar-nav > li > a.active:hover, nav.navbar.navbar-default .navbar-collapse ul.nav.navbar-nav > li > a.active:focus {
  background-color: #1E2731 !important;
  color: white;
}
nav.navbar.navbar-default .navbar-collapse ul.nav.navbar-nav > li.open > a > span.icon-arrow_right::before {
  content: "";
}
nav.navbar.navbar-default .navbar-collapse ul.nav.navbar-nav > li.open > a, nav.navbar.navbar-default .navbar-collapse ul.nav.navbar-nav > li.open > a:focus, nav.navbar.navbar-default .navbar-collapse ul.nav.navbar-nav > li.open > a:hover {
  background-color: #1E2731 !important;
  color: white;
}
nav.navbar.navbar-default .navbar-collapse ul.nav.navbar-nav > li.open > a > span.icon-arrow_left, nav.navbar.navbar-default .navbar-collapse ul.nav.navbar-nav > li.open > a:focus > span.icon-arrow_left, nav.navbar.navbar-default .navbar-collapse ul.nav.navbar-nav > li.open > a:hover > span.icon-arrow_left {
  display: inline;
  margin-right: 10px;
}
nav.navbar.navbar-default .navbar-collapse ul.nav.navbar-nav > li > ul {
  padding: 0;
}
nav.navbar.navbar-default .navbar-collapse ul.nav.navbar-nav > li > ul > li > a {
  padding: 20px 20px 20px 40px;
  border-bottom: 1px solid #D5D5D5;
  font-size: 16px;
}
nav.navbar.navbar-default .navbar-collapse ul.nav.navbar-nav > li > ul > li > a > span.subline {
  font-size: 12px;
  font-weight: 300;
  color: #B7B7B7;
}
nav.navbar.navbar-default .navbar-collapse ul.nav.navbar-nav > li > ul > li.open > a span.icon-arrow_right::before {
  content: "";
}
nav.navbar.navbar-default .navbar-collapse ul.nav.navbar-nav > li > ul > li > ul {
  padding: 0;
  margin: 0;
  background-color: #FAFAFA;
  border-bottom: 1px solid #D5D5D5;
}
nav.navbar.navbar-default .navbar-collapse ul.nav.navbar-nav > li > ul > li > ul > li > a {
  padding: 20px 20px 20px 60px;
  font-size: 16px;
}
nav.navbar.navbar-default .navbar-collapse ul.nav.navbar-nav > li > ul > li > ul > li > ul {
  padding: 0;
}
nav.navbar.navbar-default .navbar-collapse ul.nav.navbar-nav > li > ul > li > ul > li > ul > li > a {
  padding: 20px 20px 20px 80px;
  font-size: 16px;
}
nav.navbar.navbar-default .navbar-collapse ul.nav.navbar-nav > li > ul > li > ul > li > ul > li > a > span.subline {
  font-size: 12px;
  font-weight: 300;
  color: #B7B7B7;
}
nav.navbar.navbar-default .navbar-collapse ul.nav.navbar-nav > li > ul > li > ul > li.open > a span.icon-arrow_right::before {
  content: "";
}
nav.navbar.navbar-default a.navbar-brand {
  margin: 10px 5px;
}
nav.navbar.navbar-default ul.meta-nav {
  margin: 0 -15px;
  padding: 10px 0;
  background-color: #f6f6f6;
}
nav.navbar.navbar-default ul.meta-nav > li > a {
  font-weight: normal;
  display: block;
  padding: 10px 20px 10px 25px;
}
nav.navbar.navbar-default ul.meta-nav > li > a > i.icon-raticons {
  padding-right: 5px;
  font-size: 20px;
}

#headerNavigationApp {
  position: fixed;
  top: 0;
  transition: top 0.2s;
  width: 100%;
  z-index: 3;
}

.mainmenu_container {
  box-shadow: rgba(0, 0, 0, 0.5) 0 2px 4px;
  background: white;
  transition: box-shadow 0.2s, background 0.2s;
}
.mainmenu_container.transparent {
  box-shadow: none;
  background: transparent;
}
.mainmenu_container.transparent .header-container .primary .menu-container ul li a {
  display: block;
  padding: 4px 14px;
  background-color: transparent;
  border: 2px solid transparent;
  border-radius: 5px;
  color: white;
  font-size: 16px;
  display: block;
  text-align: center;
  text-decoration: none;
  font-weight: 400;
}
.mainmenu_container.transparent .header-container .meta-nav .fc-meta-nav-gey {
  color: white;
}
.mainmenu_container.transparent .header-container .primary .logo-container #rat_logo_svg #rat_logo_rational, .mainmenu_container.transparent .header-container .primary .logo-container #rat_logo_svg #rat_logo_arr_red {
  fill: #ffffff;
}
.mainmenu_container.transparent .header-container .primary .logo-container #rat_logo_svg #rat_logo_background {
  fill: transparent;
}
.mainmenu_container.transparent .header-container .primary .logo-container #rat_logo_svg #rat_logo_arr_blue, .mainmenu_container.transparent .header-container .primary .logo-container #rat_logo_svg #rat_logo_border {
  fill: #ffffff;
}
.mainmenu_container .header-container .meta-nav .fc-meta-nav-gey {
  transition: color 0.2s;
}

.submenu_container {
  opacity: 0;
  height: 0;
  overflow: hidden;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.5) 0 2px 4px;
  background: white;
  transition: opacity 0.1s ease;
}
.submenu_container .close_menu_button {
  display: none;
  position: absolute;
  cursor: pointer;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  font-size: 24px;
  background-color: white;
  border-radius: 100%;
  box-shadow: rgba(0, 0, 0, 0.5) 0 2px 4px;
}
.submenu_container .close_menu_button .shadow-repair {
  position: absolute;
  width: 110%;
  height: 50%;
  background-color: white;
  left: -5%;
}
.submenu_container .close_menu_button i {
  padding: 10px;
  display: block;
  z-index: 2;
  position: relative;
}
.submenu_container .close_menu_button:hover {
  background-color: red;
  color: white;
}
.submenu_container .close_menu_button:hover .shadow-repair {
  display: none;
}
.submenu_container.open {
  height: auto;
  opacity: 1;
  overflow: visible;
}
.submenu_container.open .close_menu_button {
  display: block;
}

.header-container ul.meta-nav {
  display: flex;
  justify-content: flex-end;
  margin-top: 9px;
  margin-bottom: 0px;
}
.header-container ul.meta-nav li {
  list-style-type: none;
  flex: 0 0 auto;
  padding-left: 10px;
}
.header-container .primary {
  display: flex;
  margin-top: 20px;
}
.header-container .primary .logo-container {
  flex: 0 1 300px;
}
.header-container .primary .logo-container #rat_logo_svg path {
  transition-property: fill;
  transition-duration: 0.4s;
  transition-timing-function: ease;
}
.header-container .primary .logo-container #rat_logo_svg #rat_logo_rational, .header-container .primary .logo-container #rat_logo_svg #rat_logo_arr_red {
  fill: #E30613;
}
.header-container .primary .logo-container #rat_logo_svg #rat_logo_background {
  fill: #ffffff;
}
.header-container .primary .logo-container #rat_logo_svg #rat_logo_arr_blue, .header-container .primary .logo-container #rat_logo_svg #rat_logo_border {
  fill: #0E3C75;
}
.header-container .primary .menu-container {
  flex: 1 1 auto;
}
.header-container .primary .menu-container ul {
  display: flex;
  justify-content: space-between;
}
.header-container .primary .menu-container ul li {
  flex: 0 1 auto;
  display: block;
}
.header-container .primary .menu-container ul li a {
  display: block;
  padding: 4px 14px;
  background-color: white;
  border: 2px solid white;
  border-radius: 5px;
  color: #333;
  font-size: 16px;
  display: block;
  text-align: center;
  text-decoration: none;
  font-weight: 400;
}
.header-container .primary .menu-container ul li.selected a,
.header-container .primary .menu-container ul li a:hover {
  background-color: #333;
  border: 2px solid #333;
  border-radius: 5px;
  color: white;
  transition-property: background-color, color, border;
  transition-duration: 0.4s;
  transition-timing-function: ease;
}
.header-container .sub-container {
  display: flex;
}
.header-container .sub-container .image-col, .header-container .sub-container .menu-col {
  flex: 0 0 25%;
}
.header-container .sub-container .image-col {
  margin: 30px 0;
}
.header-container .sub-container .menu-col {
  padding: 0 10px 0 20px;
  border-right: 1px solid #d4d4d4;
  margin: 30px 0;
}
.header-container .sub-container .menu-col ul {
  margin: 0;
  padding: 0;
  animation: subMenuIn 0.5s both;
}
@keyframes subMenuIn {
  from {
    opacity: 0;
    transform: translateX(20px);
  }
}
.header-container .sub-container .menu-col ul li {
  list-style-type: none;
  padding: 0;
}
.header-container .sub-container .menu-col ul li a {
  padding: 10px 0;
  display: block;
  font-size: 16px;
  font-weight: 400;
  transition-duration: 0.5s;
  color: #333;
}
.header-container .sub-container .menu-col ul li.selected a,
.header-container .sub-container .menu-col ul li a:hover {
  transition-duration: 0.1s;
}
.header-container .sub-container .menu-col ul li.selected a .icon-arrow_right::before,
.header-container .sub-container .menu-col ul li a:hover .icon-arrow_right::before {
  content: "";
}
.header-container .sub-container .menu-col ul li.selected .icon-arrow_right {
  transform: translateX(4px);
}
.header-container .sub-container .menu-col ul li .icon-arrow_right {
  transition-duration: 0.2s;
  display: inline-block;
}
.header-container .sub-container .menu-col ul li .description {
  font-size: 12px;
  color: #666;
  font-weight: 300;
}
.header-container .sub-container .menu-col.menu-col-2 ul li a, .header-container .sub-container .menu-col.menu-col-3 ul li a {
  font-weight: 300;
}
.header-container .sub-container .menu-col.menu-col-2 ul li.selected a,
.header-container .sub-container .menu-col.menu-col-2 ul li a:hover, .header-container .sub-container .menu-col.menu-col-3 ul li.selected a,
.header-container .sub-container .menu-col.menu-col-3 ul li a:hover {
  transition-duration: 0.1s;
}

form.search_form input.search-query {
  padding: 0 5px;
  line-height: 16px;
  height: 16px;
  background-color: transparent;
  font-size: 14px;
  text-align: right;
  max-width: 100px;
}
form.search_form button {
  border: none;
  padding: 0;
  background-color: transparent;
}
form.search_form button:hover {
  color: #E10019;
}
form.search_form.search_form_mobile {
  padding: 10px 20px 10px 25px;
}
form.search_form.search_form_mobile input.search-query {
  font-size: 16px;
  line-height: 20px;
  height: 20px;
  text-align: left;
  max-width: unset;
  padding-left: 9px;
}

/* COMPONENTS*/
.videobutton {
  background: rgba(0, 0, 0, 0.4);
  height: 100%;
  position: absolute;
  width: 100%;
}
.videobutton::before {
  color: #FFF;
  content: "";
  font-family: "raticons";
  font-size: 60px;
  font-weight: normal;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
}

@media only screen and (max-width: 577px) {
  .videobutton {
    background: none;
  }
}
.rational-btn {
  color: #3C3C3C;
  padding: 10px 30px;
  border: 2px solid #3c3c3c;
  border-radius: 5px;
  text-align: center;
  min-width: 180px;
  display: inline-block;
  transition: background-color, color 0.8s ease;
  margin-top: 5px;
}
@media only screen and (min-width: 480px) {
  .rational-btn.no-center {
    margin: 20px 0;
  }
}
@media only screen and (max-width: 767px) {
  .rational-btn:nth-child(n+1) {
    margin-top: 10px;
  }
}
.rational-btn.grey {
  background-color: #f6f6f6;
}
.rational-btn.white {
  color: white;
  border: 2px solid white;
}
.rational-btn.white:hover {
  background-color: white;
  text-decoration: none;
  color: #3C3C3C;
}
.rational-btn.red {
  background-color: red;
  color: white;
  width: 240px;
  border: none;
}
.rational-btn.redrat {
  background-color: #E2001A;
  color: white;
  width: 240px;
  border: none;
}
.rational-btn.redrat.event {
  font-weight: 700;
}
.rational-btn.redrat:hover {
  background-color: #A0C814;
  text-decoration: none;
  color: white;
}

.rational-btn:hover {
  background-color: #3c3c3c;
  text-decoration: none;
  color: white;
}

.pdf-button {
  padding-bottom: 80px;
}
@media only screen and (max-width: 767px) {
  .pdf-button {
    padding-bottom: 15px;
  }
}

.hotspot-button {
  height: 25px;
  width: 25px;
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
}

.hotspot-description {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 15px;
  right: -40px;
  top: -30px;
  max-width: 280px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 2px;
  display: none;
  z-index: 1;
}

.videocaption {
  background-color: #e10019;
  bottom: 0;
  height: 43px;
  padding: 10px 10px;
  right: 0;
  z-index: 1;
}
.videocaption::before {
  background: inherit;
  content: "";
  height: 100%;
  position: absolute;
  left: 0;
  -webkit-transform-origin: 100% 0;
  transform-origin: 100% 0;
  -webkit-transform: skew(-30deg);
  transform: skew(-30deg);
  top: 0;
  width: 100%;
  z-index: -1;
}

/* CMS PAGE TEMPLATES (page normal) */
@media only screen and (max-width: 992px) {
  .sidebar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    background: #1E2731;
    z-index: 3;
    padding-top: 4px;
  }
  .sidebar a {
    flex: 1 0 auto;
    display: block;
    color: #FFF;
    text-decoration: none;
    border-right: 1px solid #FFF;
    background: #1E2731;
  }
  .sidebar a:last-child {
    border-right: none;
  }
}
@media only screen and (min-width: 992px) {
  .sidebar {
    display: block;
    top: 250px;
    padding-left: 50px;
    right: 0;
    z-index: 1;
  }
  .sidebar .sidebar-button {
    margin-bottom: 5px;
    min-width: 50px;
    height: 50px;
    right: 4px;
    border-radius: 5px;
    padding: 6px 10px;
    background-color: #E2001A;
    color: white;
    font-size: 11px;
  }
  .sidebar .sidebar-button-green {
    margin-bottom: 5px;
    min-width: 50px;
    height: 50px;
    right: 4px;
    border-radius: 5px;
    padding: 6px 10px;
    background-color: #a0c814;
    color: white;
    font-size: 11px;
  }
  .sidebar > a {
    transition: all 0.45s;
    position: relative;
    right: 0;
  }

  .sidebar:hover > a {
    right: 10px;
  }
}
a:hover {
  text-decoration: none;
}

#back {
  color: #3C3C3C;
  top: 85%;
  display: block;
  position: fixed;
  right: 40px;
  margin-top: 40px;
  z-index: 10;
}

#back:hover {
  color: #3C3C3C;
  text-decoration: none;
}

.sidebar li {
  list-style: none;
}
.sidebar li.back {
  display: block;
  height: 20px;
  width: 20px;
  font-family: Raticons;
  content: "";
  color: black;
  position: absolute;
  cursor: pointer;
  font-size: 26px;
  top: 45%;
  left: -60px;
}
.sidebar li.back:before {
  content: "";
}
.sidebar li.next {
  color: black;
  display: block;
  height: 20px;
  width: 20px;
  font-family: Raticons;
  cursor: pointer;
  font-size: 26px;
  position: absolute;
  top: 45%;
  right: -60px;
  content: "";
}
.sidebar li.next:before {
  content: "";
}

.footer {
  padding: 33px 15px 15px;
  background-color: #f6f6f6;
}

.footer .container .row ul li a {
  color: #7d7d7d;
  font-size: 16px;
  font-weight: 300;
}

/* CMS SECTION TEMPLATES */
@media only screen and (min-width: 1200px) {
  .textBlockImageRight {
    width: 110%;
    max-width: 110%;
  }

  .textBlockImageLeft {
    width: 110%;
    max-width: 110%;
    margin-left: -10%;
  }
}
.content-video-pic-container {
  overflow: visible;
}

.popup {
  position: fixed;
  top: 5%;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}
@media only screen and (min-width: 991px) {
  .popup {
    top: 20%;
  }
}

.popup-content {
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  outline: 0;
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
}

.popup-info-text {
  line-height: 22px;
}

.popup-closer:before {
  font-family: Raticons;
  content: "";
  text-align: center;
  font-size: 26px;
}

.popup-closer {
  font-family: Raticons;
  width: 36px;
  height: 36px;
  border-radius: 20px;
  left: 50%;
  transform: translate(-50%, -50%);
}
@media only screen and (max-width: 767px) {
  .popup-closer {
    top: 100%;
  }
}

.header-banner {
  height: 470px;
  background-size: cover;
  background-position: center;
  position: relative;
}
.header-banner.sectors {
  height: 545px;
}
@media only screen and (max-width: 767px) {
  .header-banner.sectors {
    height: 315px;
  }
}
@media only screen and (max-width: 767px) {
  .header-banner {
    height: 315px;
  }
}
.header-banner .header-banner-content {
  position: absolute;
  top: 60%;
}
.header-banner .header-banner-content #header-banner-first-line {
  margin-bottom: 10px;
}
@media only screen and (max-width: 767px) {
  .header-banner .header-banner-content #header-banner-second-line {
    line-height: 23px;
  }
}

.product-link .product-line {
  border-top: 5px solid;
}
.product-link .product-link-text {
  margin: 10px;
}
.product-link .product-link-text .layer {
  background-color: white;
  left: 0;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transform: rotateX(90deg);
  transition: all 0.5s ease;
}
.product-link .product-link-text:hover .layer {
  transform: rotatex(0deg);
}

.specification-banner {
  height: 500px;
  background-size: cover;
  background-position: center;
}
@media only screen and (max-width: 767px) {
  .specification-banner {
    height: 315px;
  }
}
.specification-banner .specification-banner-content {
  bottom: 150px;
}
@media (max-width: 767px) {
  .specification-banner .specification-banner-content {
    bottom: 80px;
  }
}

.banner-sticker2 {
  height: 70px;
  bottom: 40px;
  line-height: 66px;
}
@media (max-width: 767px) {
  .banner-sticker2 {
    height: 50px;
    line-height: 52px;
    bottom: 20px;
  }
}
.banner-sticker2::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 70px;
  height: 100%;
  transform-origin: 100% 0;
  transform: skew(30deg);
  background: inherit;
}
.banner-sticker2::after {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 100%;
  width: 100vw;
  background: inherit;
  content: "";
  height: 100%;
}

.shortlink {
  border: 2px solid #3c3c3c;
  border-radius: 5px;
  padding: 20px 0;
  font-weight: 300;
}
@media only screen and (max-width: 767px) {
  .shortlink {
    padding: 0;
  }
}

.shortlink:before {
  padding: 20px 0;
  font-family: Raticons;
  line-height: 140%;
}

.shortlink:hover {
  color: white;
  background-color: #3c3c3c;
}

.shortlink p {
  padding: 0;
}

@media (max-width: 1199px) {
  .shortlinks .shortlink-col {
    margin-bottom: 20px;
  }
}

.ctabtn {
  color: white;
  display: inline-block;
  opacity: 0.9;
  padding: 8px 8px;
  text-decoration: none;
  border-radius: 5px;
}
@media only screen and (max-width: 767px) {
  .ctabtn {
    display: inline-block;
    opacity: 0.9;
    text-decoration: none;
    padding: 3px 15px;
  }
}

.ctabtn:hover, .ctabtn:focus, .ctabtn:visited {
  color: white;
  opacity: 1;
}

.try-line {
  height: 70px;
  box-shadow: rgba(0, 0, 0, 0.5) 0 -2px 3px;
  z-index: 1;
}
@media only screen and (max-width: 992px) {
  .try-line {
    bottom: 49px;
  }
}

.home-product {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.home-product .home-product-text {
  background-color: rgba(60, 60, 60, 0.2);
  left: 0;
  height: 100%;
}
.home-product .home-product-text .home-product-text-inner {
  top: 50%;
  transform: translateY(-50%);
}
@media only screen and (max-width: 767px) {
  .home-product .home-product-text .home-product-description {
    display: none;
  }
}
.home-product .home-product-text .home-product-hover-info {
  display: none;
}
@media only screen and (max-width: 767px) {
  .home-product:hover {
    background-color: rgba(60, 60, 60, 0.4);
    justify-content: initial;
    padding-top: 0;
  }
}
.home-product:hover .home-product-text {
  background-color: rgba(60, 60, 60, 0.7);
  justify-content: initial;
  padding-top: 25px;
}
.home-product:hover .home-product-hover-info {
  display: block;
}

.online-magazine-section {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.online-magazine-section .online-magazine-text {
  padding: 0 50px;
}
@media only screen and (max-width: 767px) {
  .online-magazine-section .online-magazine-text {
    padding: 15px;
  }
}
.online-magazine-section .online-magazine-text .magazine-description {
  width: 265px;
}
@media only screen and (max-width: 767px) {
  .online-magazine-section .online-magazine-text .magazine-description {
    width: auto;
  }
}

.news-headline {
  margin-top: 0;
  margin-left: 0;
}

.news-subheadline {
  margin-top: 0;
  margin-left: 0;
}

.news-image img {
  width: 100%;
}

.news-slide {
  padding: 10px;
}

.news-date {
  float: right;
  margin: 5px;
}
.news-date.more {
  float: none;
}

.quote-section {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-top: 5%;
  padding-bottom: 5%;
}

.quote-line {
  height: 2px;
  top: 30px;
}
@media only screen and (max-width: 767px) {
  .quote-line {
    top: 15px;
  }
}

.quote {
  text-align: center;
}

.quote-symbol {
  height: 80px;
  width: 80px;
  position: relative;
  border-radius: 100%;
  border: 2px solid white;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  display: inline-block;
  background-size: cover;
  background-position: center;
}
@media only screen and (max-width: 767px) {
  .quote-symbol {
    width: 40px;
    height: 40px;
    left: -10px;
  }
}

.quote-content {
  padding: 0 200px;
}
@media only screen and (max-width: 767px) {
  .quote-content {
    padding: 0;
  }
}

.header-text {
  padding: 0 15px;
  margin: 0 auto;
  bottom: 15%;
}

.slide-image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.slider-row-height {
  height: 500px;
}

@media only screen and (max-width: 576px) {
  .slider-row-height {
    height: 250px;
  }
}
@media only screen and (max-width: 767px) {
  .slide {
    max-height: 300px;
  }
  .slide img {
    max-height: 300px;
  }
}

.slide img {
  width: 100%;
}

@media only screen and (min-width: 1720px) {
  .container.gallery-container {
    width: 1410px;
    overflow: hidden;
  }
}
.container.gallery-container ul.slick-dots li:before {
  content: "";
}
.container.gallery-container .slick-list.draggable {
  overflow: visible;
}
.container.gallery-container .slick-arrow {
  display: none;
}
.container.gallery-container .gallery-prev,
.container.gallery-container .gallery-next {
  top: 0;
  width: calc( (1410px - 1140px) / 2 );
  background: linear-gradient(to right, white, rgba(255, 255, 255, 0) 5%);
  z-index: 1;
}
.container.gallery-container .gallery-prev .gallery-icon-container,
.container.gallery-container .gallery-next .gallery-icon-container {
  cursor: pointer;
  padding: 10px;
  line-height: 0;
  top: calc(50% - 39px);
}
.container.gallery-container .gallery-prev {
  left: 0;
}
.container.gallery-container .gallery-next {
  right: 0;
  background: linear-gradient(to left, white, rgba(255, 255, 255, 0) 5%);
}
@media only screen and (max-width: 1720px) {
  .container.gallery-container .gallery-prev,
.container.gallery-container .gallery-next {
    background: none;
  }
}
@media only screen and (max-width: 767px) {
  .container.gallery-container .gallery-prev,
.container.gallery-container .gallery-next {
    background: none;
    width: 0;
  }
  .container.gallery-container .gallery-prev .gallery-icon-container,
.container.gallery-container .gallery-next .gallery-icon-container {
    padding: 3px;
    line-height: 0;
    top: calc(50% - 36px);
  }
  .container.gallery-container .gallery-prev {
    left: 38px;
  }
  .container.gallery-container .gallery-next {
    right: 37px;
  }
}

[class^=modal-slick-gallery_] {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background-color: rgba(255, 255, 255, 0.8);
}
[class^=modal-slick-gallery_] .gallery-close-icon {
  z-index: 1;
  top: -1px;
  right: 14px;
  cursor: pointer;
  padding: 10px;
  line-height: 0;
}
[class^=modal-slick-gallery_] .gallery-prev,
[class^=modal-slick-gallery_] .gallery-next {
  top: 0;
  z-index: 1;
}
[class^=modal-slick-gallery_] .gallery-prev .gallery-icon-container,
[class^=modal-slick-gallery_] .gallery-next .gallery-icon-container {
  cursor: pointer;
  padding: 10px;
  line-height: 0;
  top: calc(50% - 19px);
}
[class^=modal-slick-gallery_] .gallery-prev {
  left: -0.5px;
}
[class^=modal-slick-gallery_] .gallery-prev .gallery-icon-container {
  left: 15px;
}
[class^=modal-slick-gallery_] .gallery-next {
  right: -1px;
}
[class^=modal-slick-gallery_] .gallery-next .gallery-icon-container {
  right: 15px;
}

.header-information-row {
  padding: 80px 0;
}
@media only screen and (max-width: 767px) {
  .header-information-row {
    padding: 20px 0;
  }
}
@media only screen and (max-width: 767px) {
  .header-information-row .sector-text {
    padding: 10px 15px;
  }
}
.header-information-row .sector-text.icons img {
  margin-right: 25px;
}
.header-information-row .sector-text .box {
  display: flex;
  margin-bottom: 10px;
}

.cook-info {
  right: -550px;
  transition: 1s;
  width: 900px;
  height: 220px;
  background-color: #0082be;
  position: relative;
  float: right;
  padding: 50px 80px;
}
@media only screen and (max-width: 767px) {
  .cook-info {
    right: -150px;
    width: 300px;
    height: 150px;
    padding: 15px 20px;
    margin-bottom: 45px;
  }
}
.cook-info .info-toggle:before {
  cursor: pointer;
  font-family: Raticons;
  content: "";
  text-align: center;
  font-size: 26px;
}
.cook-info .info-toggle {
  cursor: pointer;
  position: absolute;
  top: 110px;
  left: 0;
  transform: translate(-50%, -50%);
  font-family: Raticons;
  width: 36px;
  height: 36px;
  border-radius: 20px;
  content: "";
  line-height: 36px;
}
@media only screen and (max-width: 767px) {
  .cook-info .info-toggle {
    top: 145px;
  }
}
.cook-info:hover {
  right: 0;
  transition: 1s;
}
.cook-info:hover .info-toggle:before {
  content: "";
}

.social-feed {
  background-color: #3C3C3C;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
}
.social-feed .social-headline {
  margin-bottom: 40px;
  margin-top: 30px;
}
.social-feed .social-headline .feed-icon:before {
  font-family: Raticons;
  content: "";
  font-size: 36px;
  padding-top: 10px;
}

.modal-slide {
  width: 100%;
  height: 100%;
}

.modal-slider {
  height: 100%;
}

.modal-slider-section {
  padding: 40px;
}

.modal-slider-arrow {
  top: 50%;
  position: absolute;
  font-size: 22px;
}

.modal-slider-left {
  left: -35px;
}

.modal-slider-right {
  right: -35px;
}

.videobtn2020 {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  background: rgba(0, 0, 0, 0.4);
}
.videobtn2020:after {
  color: #FFF;
  content: "";
  font-family: "raticons";
  font-size: 60px;
  font-weight: normal;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@media (min-width: 768px) {
  .videobtn2020:after {
    font-size: 120px;
  }
}
.notificationBar {
  z-index: 5;
  display: none;
  cursor: pointer;
}
.notificationBar .close_notificationbar_button {
  position: absolute;
  cursor: pointer;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 50%);
  font-size: 24px;
  background-color: white;
  border-radius: 100%;
}
.notificationBar .close_notificationbar_button i {
  padding: 10px;
  display: block;
  z-index: 2;
  position: relative;
}
.notificationBar .close_notificationbar_button:hover {
  background-color: red;
  color: white;
}
.notificationBar .close_notificationbar_button:hover .shadow-repair {
  display: none;
}

.four_blocks_auto_align-1 {
  left: calc(100% * (1 - 1 * 0.25) / 2);
}

.four_blocks_auto_align-2 {
  left: calc(100% * (1 - 2 * 0.25) / 2);
}

.four_blocks_auto_align-3 {
  left: calc(100% * (1 - 3 * 0.25) / 2);
}

.four_blocks_auto_align-4 {
  left: calc(100% * (1 - 4 * 0.25) / 2);
}

@media only screen and (max-width: 991px) {
  div[class*=four_blocks_auto_align-] {
    left: revert;
  }
}
.list-style-type-0,
.list-style-type-1 {
  list-style-type: disc;
}

.list-style-type-2 {
  list-style-type: decimal;
}

.list-style-type-3 {
  list-style-type: lower-latin;
}

.list-style-type-4 {
  list-style-type: upper-latin;
}

.list-style-type-5 {
  list-style-type: lower-roman;
}

.list-style-type-6 {
  list-style-type: upper-roman;
}

.list-style-type-7 {
  list-style-type: none;
}

/***
MC iFrames
 */
.mciframe {
  border: solid 0.5px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.5) 2px 2px 4px;
  padding: 50px 0 0 0;
}

/***
usercentrics
*/
.uc-embedding-container {
  overflow: hidden !important;
}

.uc-embedding-container .uc-embedding-wrapper {
  width: 752px;
}

.uc-embedding-container .uc-embedding-buttons .uc-embedding-accept {
  background-color: #a0c814;
}

/***
Development
 */

.fullsection {
height: 100vh;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
}
.row-4-blocks .four-blocks-picture-link {
	padding-left: 0;	
}
.row-4-blocks .four-blocks-picture-link:before {
	content: none;
}

.fbspecial img {
  border: 1px solid #3c3c3c;
  border-radius: 5px;
  -webkit-box-shadow: 5px 5px 5px 1px rgb(60 60 60 / 52%); 
  box-shadow: 1px 1px 1px 1px rgb(60 60 60 / 52%);
}
