@import "@spartacus/cart";
@import "../rational-theme/rational-commons";

.CartPageTemplate {
    .CenterRightContentSlot.has-components {
        @media (max-width: 992px) {
            flex: 1 1 var(--cx-flex-basis, 100%);
            --cx-flex-basis: 100%;
            flex-direction: unset;
            justify-content: flex-start;

            cx-cart-totals {
                flex: 1 1 var(--cx-flex-basis, 100%);
                max-width: 100%;
                padding-left: 0px;
            }

            cx-cart-proceed-to-checkout {
                flex: 1 1 var(--cx-flex-basis, 100%);
                align-self: flex-start;
                max-width: unset;
                padding-left: 0px;
            }
        }

        cx-cart-proceed-to-checkout cx-progress-button button {
            @extend %affirmative-buttons;
        }

    }
}

cx-added-to-cart-dialog {
    div.cx-modal-container div.cx-dialog-body div.cx-dialog-buttons {
        a.btn-secondary {
            @extend %affirmative-buttons;
        }

        a.btn-primary {
            @extend %non-affirmative-buttons;
        }
    }
}
cx-payment-type, cx-delivery-mode, cx-delivery-address {
    border: none !important;
}

cx-payment-type div.cx-checkout-btns,
cx-delivery-address div.cx-checkout-btns,
cx-delivery-mode div.cx-checkout-btns,
cx-place-order {
    button.btn.btn-primary {
        @extend %affirmative-buttons;
    }

    button.btn.btn-secondary {
        @extend %non-affirmative-buttons;
    }
}

cx-delivery-mode .cx-delivery-price,
cx-delivery-mode  .cx-delivery-mode-description {
    display: none !important
}

.cx-saved-cart-form-body .cx-saved-cart-form-footer {
    button.btn.btn-primary {
        @extend %affirmative-buttons;
    }

    button.btn.btn-secondary {
        @extend %non-affirmative-buttons;
    }
}

cx-import-entries-form .cx-import-entries-footer {
    button.btn.btn-primary {
        @extend %affirmative-buttons;
    }

    button.btn.btn-secondary {
        @extend %non-affirmative-buttons;
    }
}


cx-delivery-address {
    .ng-select .ng-select-container .ng-value-container .ng-placeholder {
        color: $secondary_darkgrey !important;
    }
}
