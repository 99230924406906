@import "@spartacus/order";

rational-amend-order-items {
    @extend %cx-amend-order-items;
}

cx-order-confirmation-thank-you-message {
    .cx-order-confirmation-message {
        max-width: var(--cx-page-width-max);
        margin: auto;
    }
}

cx-order-overview {
    .cx-order-summary {
        max-width: var(--cx-page-width-max);
        margin: auto;
    }
}

.cx-billing-invoices-dialog ,
.cx-shippings-information-dialog  {

  .trackingLink {
    position: relative;
    text-align: center;

    .cx-value {
      cursor: pointer;
      top: 0;
      right: 10px;

      &.is-active {
        rotate: 180deg;
      }
    }
  }

  .downloadLink {
    position: relative;

    .cx-value {
      position: absolute;
      cursor: pointer;
      top: 0;
      right: 10px;

      &.is-active {
        rotate: 180deg;
      }
    }
  }

.cx-document-body, cx-order-tracking-documents {
  cx-spinner .loader-container, cx-spinner .loader {
    height: 70px !important;
    width: 70px !important;
  }

  cx-spinner .loader::before {
    height: 70px !important;
    width: 70px !important;
    border: 12px solid;
    color: var(--cx-spinner-secondary-color);
  }
}
}

cx-order-documents-list , cx-order-tracking-documents {
  width: 100%;
}

cx-order-history .cx-order-history-pagination-jump-to {
  width: auto;
  form {
    width: 65px;
  }
}

cx-order-history .cx-order-history-header {
  padding-inline-start: 0px;
}

cx-order-history .cx-order-history-sort.top {
  justify-content: end;

  @include media-breakpoint-down(sm) {
    flex-direction: row;
  }

  .cx-order-history-form-group {
    justify-content: end;

    @include media-breakpoint-down(sm) {
      padding: 0;
    }
    @include media-breakpoint-down(xs) {
      margin-top: 10px;
    }
  }

  cx-pagination {
    justify-content: end;
  }
}
