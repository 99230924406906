@import "@spartacus/product";
@import "../rational-theme/rational-commons";


.ProductDetailsPageTemplate cx-add-to-cart form>button {
  @extend %affirmative-buttons;
}

.ProductDetailsPageTemplate cx-configure-product button {
  @extend %affirmative-buttons;
  padding: 15px 30px;

}

rational-product-list-item cx-add-to-cart form>button {
    @extend %non-affirmative-buttons;
}

cx-page-layout.ProductDetailsPageTemplate cx-page-slot.Summary {
  cx-product-images {
    grid-row: 1/span 7 !important;
    > cx-media {
      min-height: 400px !important;
      min-width: 450px !important;
      height: auto !important;
      img {
        width: 400px !important;
        height: 450px !important;
      }
    }
    @media (min-width: 992px) {
      > cx-media {
        min-height: 550px !important;
        min-width: 515px !important;
        height: auto !important;
        img {
          height: 550px !important;
          width: 515px !important;
        }
      }
    }
    > cx-carousel{
     display: flex;
     flex: 0 !important;
   }
  }
  rational-standard-delivery {
    grid-row: 3;
  }



}
.disclaimer >p{
  font-size: 11px;
}


@media (max-width: 471px) {
  cx-product-attributes {
    >div.container {
      margin: 0px;
      padding: 0px;
      width: 100%;


      >table {
        table-layout: fixed;
        width: 100%;

        tr>td {
          word-wrap: break-all;
          hyphens: auto !important;
          max-width: 60%;

          &:first-child {
            padding-inline-end: 4px;
          }
        }

      }
    }
  }
}

.refImg {
  img {
    object-fit: contain;
  }
}
