
.icon-raticons {
    font-family: 'raticons' !important;
    speak: none;
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
  &.icon-share_club{
    font-size: 22px;
  }
}


.icon-arrow_leri:before {
    content: "\e8e1";
}

.icon-arrow_down:before {
    content: "\e901";
}

.icon-arrow_right:before {
    content: "\e902";
}

.icon-arrow_left:before {
    content: "\e903";
}

.icon-arrow_backward:before {
    content: "\e904";
}

.icon-arrow_forward:before {
    content: "\e905";
}

.icon-share_club:before {
    content: "\e906";
}

.icon-arrow_todo:before {
    content: "\e907";
}

.icon-arrow2_up:before {
    content: "\e908";
}

.icon-arrow2_down:before {
    content: "\e909";
}

.icon-arrow2_left:before {
    content: "\e90a";
}

.icon-arrow2_right:before {
    content: "\e90b";
}

.icon-arrow2_down1:before {
    content: "\e90c";
}

.icon-arrow2_up1:before {
    content: "\e90d";
}

.icon-close:before {
    content: "\e90e";
}

.icon-sn_burger:before {
    content: "\e90f";
}

.icon-sn_search:before {
    content: "\e910";
}

.icon-sn_international:before {
    content: "\e911";
}

.icon-kl_chefline:before {
    content: "\e912";
}

.icon-kl_service:before {
    content: "\e913";
}

.icon-kl_contact:before {
    content: "\e914";
}

.icon-kl_dealer:before {
    content: "\e915";
}

.icon-video:before {
    content: "\e916";
}

.icon-sn_international_s:before {
    content: "\e917";
}

.icon-star:before {
    content: "\e918";
}

.icon-star_full:before {
    content: "\e919";
}

.icon-check:before {
    content: "\e91a";
}

.icon-check_full:before {
    content: "\e91b";
}

.icon-list:before {
    content: "\e91c";
}

.icon-list_full:before {
    content: "\e91d";
}

.icon-plus:before {
    content: "\e91e";
}

.icon-plus_full:before {
    content: "\e91f";
}

.icon-configurator:before {
    content: "\e920";
}

.icon-calc:before {
    content: "\e921";
}

.icon-view:before {
    content: "\e922";
}

.icon-download:before {
    content: "\e923";
}

.icon-upload:before {
    content: "\e924";
}

.icon-grid_grid:before {
    content: "\e925";
}

.icon-grid_list:before {
    content: "\e926";
}

.icon-date:before {
    content: "\e927";
}

.icon-time:before {
    content: "\e928";
}

.icon-arrow_btn_up:before {
    content: "\e929";
}

.icon-event_out:before {
    content: "\e92a";
}

.icon-mail:before {
    content: "\e92b";
}

.icon-phone:before {
    content: "\e92c";
}

.icon-search_s:before {
    content: "\e92d";
}

.icon-bubble:before {
    content: "\e92e";
}

.icon-share:before {
    content: "\e92f";
}

.icon-print:before {
    content: "\e930";
}

.icon-share_facebook:before {
    content: "\e931";
}

.icon-share_twitter:before {
    content: "\e932";
}

.icon-share_google:before {
    content: "\e933";
}

.icon-share_instagram:before {
    content: "\e934";
}

.icon-share_youtube:before {
    content: "\e935";
}

.icon-share_linkedin:before {
    content: "\e936";
}

.icon-share_pinterest:before {
    content: "\e937";
}

.icon-burger_close:before {
    content: "\e938";
}

.icon-warning:before {
    content: "\e939";
}

.icon-arrow_btn_down:before {
    content: "\e93a";
}

.icon-arrow_btn_left:before {
    content: "\e93b";
}

.icon-arrow_btn_right:before {
    content: "\e93c";
}

.icon-arrow_btn_backward:before {
    content: "\e93d";
}

.icon-arrow_btn_forward:before {
    content: "\e93e";
}

.icon-share_youku:before {
    content: "\e93f";
}

.icon-duration:before {
    content: "\e940";
}

.icon-info:before {
    content: "\e941";
}

.icon-question:before {
    content: "\e942";
}

.icon-share_kununu:before {
    content: "\e943";
}

.icon-cc_international:before {
    content: "\e944";
}

.icon-empty:before {
    content: "\e945";
}

.icon-cc_settings:before {
    content: "\e946";
}

.icon-cc_tree:before {
    content: "\e947";
}

.icon-casual_dining:before {
    content: "\e948";
}

.icon-cc_vcc_reset:before {
    content: "\e949";
}

.icon-cc_api:before {
    content: "\e94a";
}

.icon-cc_grid_list:before {
    content: "\e94b";
}

.icon-cc_casual_dining:before {
    content: "\e94c";
}

.icon-cc_vcc_scc:before {
    content: "\e94d";
}

.icon-cc_vcc:before {
    content: "\e94e";
}

.icon-cc_scc:before {
    content: "\e94f";
}

.icon-cc_cloud:before {
    content: "\e950";
}

.icon-cc_config_multi:before {
    content: "\e951";
}

.icon-cc_contact:before {
    content: "\e952";
}

.icon-cc_lock:before {
    content: "\e953";
}

.icon-butcher:before {
    content: "\e954";
}

.icon-jail:before {
    content: "\e955";
}

.icon-canteen2:before {
    content: "\e956";
}

.icon-school:before {
    content: "\e957";
}

.icon-pot:before {
    content: "\e958";
}

.icon-cc_chart:before {
    content: "\e959";
}

.icon-hospital:before {
    content: "\e95a";
}

.icon-cc_app:before {
    content: "\e95b";
}

.icon-marina:before {
    content: "\e95c";
}

.icon-restaurant:before {
    content: "\e95d";
}

.icon-quick_service:before {
    content: "\e95e";
}

.icon-gas_stop:before {
    content: "\e95f";
}

.icon-canteen:before {
    content: "\e960";
}

.icon-cc_key:before {
    content: "\e961";
}

.icon-bakery:before {
    content: "\e962";
}

.icon-cc_play:before {
    content: "\e963";
}

.icon-supermarket:before {
    content: "\e964";
}

.icon-bubble2:before {
    content: "\e965";
}

.icon-hand:before {
    content: "\e966";
}

.icon-view2:before {
    content: "\e967";
}

.icon-sn_international_s2:before {
    content: "\e968";
}

.icon-cc_check:before {
    content: "\e969";
}

.icon-book:before {
    content: "\e96a";
}

.icon-cc_scc_reset:before {
    content: "\e96c";
}

.icon-search_s1:before {
    content: "\e96d";
}

.icon-burger_close1:before {
    content: "\e96e";
}

.icon-arrow_circled_right:before {
    content: "\e96f";
}

.icon-arrow_up:before {
    content: "\e900";
}

.icon-cc_restaurant:before {
    content: "\e96b";
}

.icon-quotation:before {
    content: "\e970";
}

.icon-news:before {
    content: "\e971";
}

.icon-career:before {
    content: "\e972";
}

.icon-UX-Icon_product_iVarioeps_96572:before {
    content: "\e973";
}

.icon-Icons_Profil:before {
    content: "\e974";
}

.icon-icon_Heart:before {
    content: "\e975";
}

.icon-icon_Heart_full:before {
    content: "\e976";
}

.icon-Zoom-Icon:before {
    content: "\e977";
}

.icon-arrow_left_2:before {
    content: "\e978";
}

.icon-arrow_right_2:before {
    content: "\e979";
}
