@import "@spartacus/product";
@import "../rational-theme/rational-commons";


.ProductDetailsPageTemplate cx-add-to-cart form>button {
  @extend %affirmative-buttons;
}

.ProductDetailsPageTemplate cx-configure-product a.btn.btn-primary {
  @extend %affirmative-buttons;
  padding: 15px 30px;
  
}

rational-product-list-item cx-add-to-cart form>button {
    @extend %non-affirmative-buttons;
}

cx-page-layout.ProductDetailsPageTemplate cx-page-slot.Summary {
  cx-product-images {
    grid-row: 1/span 7 !important;
    > cx-media {
      min-height: 400px !important;
      min-width: 450px !important;

      img {
        width: 400px !important;
        height: 450px !important;
      }
    }
    @media (min-width: 992px) {
      > cx-media {
        min-height: 550px !important;
        min-width: 515px !important;
        img {
          height: 550px !important;
          width: 515px !important;
        }
      }
    }

  }
  rational-standard-delivery {
    grid-row: 3;
  }

  cx-add-to-cart {
    grid-row: 5 !important;
  }

  cx-configure-product {
    grid-row: 6 !important;
  }

}


@media (max-width: 471px) {
  cx-product-attributes {
    >div.container {
      margin: 0px;
      padding: 0px;
      width: 100%;


      >table {
        table-layout: fixed;
        width: 100%;

        tr>td {
          word-wrap: break-all;
          hyphens: auto !important;
          max-width: 60%;

          &:first-child {
            padding-inline-end: 4px;
          }
        }

      }
    }
  }
}

.refImg {
  img {
    object-fit: contain;
  }
}
