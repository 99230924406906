cx-navigation-ui>nav>ul>li>.wrapper>ul>li>cx-generic-link:first-child {
    font-weight: bold !important;
}

cx-navigation-ui>nav>ul>li>.wrapper>ul>li {
    margin-inline: 10px;
}

cx-anonymous-consent-open-dialog {
    margin: 15px 0px 15px 0px !important;
    justify-content: flex-start !important;
    
    button {
        margin: 0px;
        color: var(--cx-color-text) !important;
    }
}

// only for mobile view
@media (max-width: 991.98px) {
    nav>ul>li.is-open>.wrapper>ul>li {
        display: flex !important;
        flex-direction: row;
    }
    nav>ul>li.is-open {
        display: flex !important;
        flex-direction: column;
    }

    nav>ul>li>nav>ul>li.is-opened>.wrapper>ul>li {
        display: flex !important;
        flex-direction: row;
    }
}