@import "./rational-colors.scss";

// Dark grey button
%affirmative-buttons {
  &, &:hover, &:focus, &:active {
      background-color: $primary_darkgrey !important;
      color: $secondary_white !important;
      border-color: $primary_darkgrey !important;
      box-shadow:  none !important;
  }
}

// White button
%non-affirmative-buttons {
  &, &:hover, &:focus, &:active {
      color: $primary_darkgrey !important;
      background-color: $secondary_white !important;
      border-color: $primary_darkgrey !important;
      box-shadow:  none !important;
  }
}

%header-footer-container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;



  @media (min-width: 992px) {
    width: 970px;
  }

  @media (min-width: 1200px) {
    width: 1170px;
  }
}
.loader::before {
  border-left-color: #fe5757 !important
}

.cx-action-link {
  color: var(--cx-color-text) !important;
  &:hover {
    color: var(--cx-color-primary) !important;
  }
}


cx-export-order-entries, cx-import-order-entries {
  button {
      padding: 0px !important;
      &:hover {
        color: var(--cx-color-primary) !important;
      }
  }
}

.autocomplete-items {
  position: absolute;
  border: 1px solid #d4d4d4;
  background-color: #fff;
  z-index: 99;
  width: 20em;
  max-height: 200px;
  overflow-y: auto;
  font-size: var(--cx-font-size) !important;
}

.autocomplete-item {
  padding: 10px;
  cursor: pointer;
  border-bottom: 1px solid #d4d4d4;
}

.autocomplete-item:hover {
  background-color: #e9e9e9;
}
