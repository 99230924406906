/* You can add global styles to this file, and also import other style files */

$styleVersion: 5.0;
@import '@spartacus/styles/index';
@import "styles/rational-theme/basegraphics";
@import "styles/rational-theme/relaunch2019-1";
@import "styles/rational-theme/rational-theme";
@import "styles/rational-theme/rational-icons";
@import "styles/rational-theme/rational-fonts";
@import "styles/rational-theme/rational-colors";
@import "styles/rational-theme/rational-commons";

@import "styles/components/_components.navigation-ui.scss";
