@font-face {
    font-family: 'Glyphicons Halflings';

    src: url('../../assets/fonts/glyphicons-halflings-regular-eot.eot');
    src: url('../../assets/fonts/glyphicons-halflings-regular-eot.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/glyphicons-halflings-regular-woff2.woff2') format('woff2'), url('../../assets/fonts/glyphicons-halflings-regular-woff.woff') format('woff'), url('../../assets/fonts/glyphicons-halflings-regular-ttf.ttf') format('truetype'), url('../../assets/fonts/glyphicons-halflings-regular-svg.svg#glyphicons_halflingsregular') format('svg');
}

@font-face {
    font-family: 'RATIONALSans';
    src: url('../../assets/fonts/rationalsansweb-light-eot.eot');
    src: url('../../assets/fonts/rationalsansweb-light-eot.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/rationalsansweb-light-woff.woff') format('woff'), url('../../assets/fonts/rationalsansweb-light-ttf.ttf') format('truetype'), url('../../assets/fonts/rationalsansweb-light-svg.svg') format('svg');
	font-weight: 300;
	font-style: normal;
}

@font-face {
    font-family: 'RATIONALSans';
    src: url('../../assets/fonts/rationalsansweb-regular-eot.eot');
    src: url('../../assets/fonts/rationalsansweb-regular-eot.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/rationalsansweb-regular-woff.woff') format('woff'), url('../../assets/fonts/rationalsansweb-regular-ttf.ttf') format('truetype'), url('../../assets/fonts/rationalsansweb-regular-svg.svg') format('svg');
	font-weight: 400;
	font-style: normal;
}

@font-face {
    font-family: 'RATIONALSans';
    src: url('../../assets/fonts/rationalsansweb-bold-eot.eot');
    src: url('../../assets/fonts/rationalsansweb-bold-eot.eot?#iefix') format('embedded-opentype'), url('../../assets/fonts/rationalsansweb-bold-woff.woff') format('woff'), url('../../assets/fonts/rationalsansweb-bold-ttf.ttf') format('truetype'), url('../../assets/fonts/rationalsansweb-bold-svg.svg') format('svg');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'raticons';
	src:url('../../assets/fonts/raticons.eot?w4q39o');
	src:url('../../assets/fonts/raticons.eot?w4q39o#iefix') format('embedded-opentype'),

		url('../../assets/fonts/raticons.ttf?w4q39o') format('truetype'),
		url('../../assets/fonts/raticons.woff?w4q39o') format('woff'),
		url('../../assets/fonts/raticons.svg?w4q39o#raticons') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

@font-face {
	font-family: 'sittingprettiesone';
	src: url('../../assets/fonts/sittingprettiesone-eot.eot');/* IE9 Compat Modes */
	src: url('../../assets/fonts/sittingprettiesone-eot.eot#iefix') format('embedded-opentype'), /* IE6-IE8 */
	  url('../../assets/fonts/sittingprettiesone-svg.svg') format('svg'), /* Legacy iOS */
	  url('../../assets/fonts/sittingprettiesone-ttf.ttf') format('truetype'), /* Safari, Android, iOS */
	  url('../../assets/fonts/sittingprettiesone-woff.woff') format('woff'), /* Modern Browsers */
	  url('../../assets/fonts/sittingprettiesone-woff2.woff2') format('woff2'); /* Modern Browsers */
	font-weight: normal;
	font-style: normal;
  }

